import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, EMPTY, Observable, Subject, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
import { StorageService } from 'src/app/storage/services/storage.service';
import { AppPartner } from 'src/partner/partner.service';
import { TokenResp } from '../models/token-resp';
import { AuthApiService } from '../services/auth-api.service';
import { CookieService } from '../services/cookie.service';
import { GlobalErrorHandler } from 'src/app/errors/global-error-handler';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private isRefreshing = false; // Flag to indicate if refresh token request is in progress
  private readonly refreshTokenSubject: Subject<string | null> =
    new BehaviorSubject<string | null>(null);

  constructor(
    private readonly authenticationService: AuthApiService,
    private readonly snackService: SnackBarService,
    private readonly router: Router,
    private readonly ngZone: NgZone,
    private readonly partner: AppPartner,
    private readonly cookieService: CookieService,
    private readonly storageService: StorageService,
    private readonly globalErrorHandler: GlobalErrorHandler,
    public dialog: MatDialog
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.handleError(error, request, next)
        )
      );
  }

  private handleError(
    error: HttpErrorResponse,
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const apiErrors = error.error?.errors || [];

    if (apiErrors.length === 0) {
      return throwError(() => error);
    }

    const { code, description } = apiErrors[0];

    if (this.isUnauthorizedError(error, code, request)) {
      return this.handleUnauthorizedError(code, description, request, next);
    } else {
      this.globalErrorHandler.handleError(error);
      return throwError(() => error);
    }
  }

  private isUnauthorizedError(
    error: HttpErrorResponse,
    code: number,
    request: HttpRequest<any>
  ): boolean {
    return (
      error.status === 401 &&
      (code === 600 || code === 601) &&
      !request.url.includes('/logout')
    );
  }

  private handleUnauthorizedError(
    code: number,
    description: string,
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (code === 600 && this.authenticationService.token?.accessToken) {
      this.snackService.showError(description);
      this.dialog.closeAll();
      this.logoutAndRedirect();
      return EMPTY;
    }

    if (code === 601) {
      return this.handle401Error(request, next);
    }

    return EMPTY;
  }

  private logoutAndRedirect(): void {
    const partnerName =
      this.partner.getPartnerValue()?.routerPartnerName?.toLowerCase() ??
      'fiserv';

    this.authenticationService
      .logout()
      .pipe(
        take(1),
        finalize(() =>
          this.ngZone.run(() => this.router.navigate([`${partnerName}/login`]))
        )
      )
      .subscribe();

    this.clearUserData();
  }

  private clearUserData(): void {
    this.storageService.remove('userDetails');
    this.storageService.remove('userChannel');
    this.cookieService.deleteFromDifferentPath('currentToken');
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authenticationService.refreshToken().pipe(
        switchMap((tokens: TokenResp) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(tokens.accessToken);
          return next.handle(
            this.addAuthorizationToken(request, tokens.accessToken)
          );
        })
      );
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap(() =>
        next.handle(
          this.addAuthorizationToken(
            request,
            this.authenticationService.token?.accessToken
          )
        )
      )
    );
  }

  private addAuthorizationToken(
    request: HttpRequest<any>,
    accessToken: string | undefined
  ): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}
