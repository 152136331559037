import { Injectable } from '@angular/core';

import { ApiService } from './common/api.service';
import { CookieService } from './cookie.service';

import { TokenResp } from '../models/token-resp';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { StorageService } from 'src/app/storage/services/storage.service';
import { Partner } from 'src/partner/models';
import { AppPartner } from 'src/partner/partner.service';
import { RoleUserEnum } from '../enums/role-user.enum';
import { UserProfile } from '../enums/user-profile.enum';
import { UserTypeEnum } from '../enums/user-type.enum';
import { Authenticate } from '../models/authentication-resp';
import { UserDetails } from '../models/user/user-details';

interface error {
  code: number;
  type: string;
  description: string;
}
interface loginReq {
  username: string;
  password: string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  partner: Partner | undefined;

  constructor(
    private _api: ApiService,
    public cookieService: CookieService,
    private _http: HttpClient,
    private _appPartner: AppPartner,
    private _storageServe: StorageService
  ) {
    this._appPartner.getPartner().subscribe((el) => (this.partner = el));
  }

  public get token(): TokenResp {
    return JSON.parse(this.cookieService.get('currentToken'));
  }

  public get userDetails(): UserDetails {
    const details = this._storageServe.get('userDetails') || null;
    if (details != null) {
      return JSON.parse(details) as UserDetails;
    }
    return {
      email: '',
      profileId: 0,
      profileName: '',
      socialName: '',
      username: '',
      name: '',
      status: '',
      userId: '0',
      document: '',
      roles: [],
    };
  }

  userDetailsUsingPrefix(prefix: string): UserDetails {
    const details =
      this._storageServe.getUsingPrefix('userDetails', prefix) || null;
    if (details != null) {
      return JSON.parse(details) as UserDetails;
    }
    return {
      email: '',
      profileId: 909012,
      profileName: '',
      socialName: '',
      username: '',
      name: '',
      status: '',
      userId: '0',
      document: '',
      roles: [],
    };
  }

  public get isAuthenticated(): boolean {
    return JSON.parse(this.cookieService.get('currentToken')).hasOwnProperty(
      'accessToken'
    );
  }

  public get isFD(): boolean {
    return (
      this.isAuthenticated && this.userDetails.userType === UserTypeEnum.FD
    );
  }
  public get isALLIANCE(): boolean {
    return (
      this.isAuthenticated &&
      this.userDetails.userType === UserTypeEnum.ALLIANCE
    );
  }

  isProfile(profileId: number): boolean {
    return profileId === this.userDetails.profileId;
  }

  login(
    email: string,
    password: string,
    redirect_uri?: string,
    client_id?: string
  ): Observable<Authenticate> {
    const body = {
      username: email,
      password,
    };

    if (redirect_uri) {
      return this._api.post<loginReq, Authenticate>(
        `/v1/authenticate?redirect_uri=${redirect_uri}&client_id=${client_id}`,
        body
      );
    }

    return this._api.post<loginReq, Authenticate>(`/v1/authenticate`, body);
  }

  logout(): Observable<Record<string, never>> {
    return this._api.post('/v1/authenticate/logout', {});
  }

  validEmail(email: string): Observable<Record<string, unknown>> {
    return this._api.get<Record<string, unknown>>(
      '/v1/public/users/user-exists/' + email,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  validToken(
    mfaId: string,
    token: string,
    client_id?: string
  ): Observable<{
    accessToken: string;
    refreshToken: string;
    scope: string;
    idToken: string;
    tokenType: string;
    expiresIn: string;
    redirectUri?: string;
    authorizationCode?: string;
  }> {
    return this._api.post<
      { mfaId: string; token: string; client_id?: string },
      {
        accessToken: string;
        refreshToken: string;
        scope: string;
        idToken: string;
        tokenType: string;
        expiresIn: string;
        redirectUri?: string;
        authorizationCode?: string;
      }
    >(`/v1/public/mfa/validate${client_id ? '?client_id=' + client_id : ''}`, {
      mfaId,
      token,
    });
  }

  resendToken(mfaId: number): Observable<{
    email: string;
    mfaId: number;
    tokenId: number;
    expiresMilliseconds: number;
    remaining_attempts: number;
    blockedTimeMilliseconds: number;
  }> {
    return this._api.post<
      { mfaId: number },
      {
        email: string;
        expiresMilliseconds: number;
        mfaId: number;
        tokenId: number;
        remaining_attempts: number;
        blockedTimeMilliseconds: number;
      }
    >('/v1/public/mfa/resend', { mfaId });
  }

  refreshToken() {
    return this._http
      .post<TokenResp>(`/v1/authenticate/refresh-token`, null, {
        headers: {
          'Content-Type': 'application/json',
          refreshToken: this.token.refreshToken,
        },
      })
      .pipe(
        tap(
          (token: TokenResp) => {
            if (token) {
              this.cookieService.deleteFromDifferentPath('currentToken');

              const pathToSaveCookie =
                this.partner?.partnerName?.toLocaleLowerCase() ?? 'fiserv';

              this.cookieService.set('currentToken', JSON.stringify(token), {
                path: window.location.pathname.includes('accessmanagement')
                  ? `/accessmanagement/${pathToSaveCookie}`
                  : `/${pathToSaveCookie}`,
                expires: token.expiresIn,
              });
            }
          },
          (_) => {
            this._storageServe.remove('userDetails');
            this._storageServe.remove('userChannel');
            this.cookieService.deleteFromDifferentPath('currentToken');
            window.location.reload();
          }
        )
      );
  }

  getUserDetails(): Observable<UserDetails> {
    const details: string | null =
      this._storageServe.get('userDetails') || null;

    if (details != null) {
      const user: UserDetails = JSON.parse(details);
      return of(user).pipe(
        tap(() =>
          this._http
            .get(`/v1/users/details`)
            .pipe(
              tap(
                (data) =>
                  this._storageServe.save('userDetails', JSON.stringify(data)),
                first()
              )
            )
            .subscribe()
        )
      );
    }

    return this.requestUserDetails();
  }

  getName() {
    return this.userDetails.name;
  }

  getSocialName() {
    return this.userDetails.socialName;
  }

  getEmail() {
    return this.userDetails.email;
  }

  getProfileName() {
    return this.userDetails.profileName;
  }

  getProfileId() {
    return this.userDetails.profileId;
  }

  userIsAdmin(): boolean {
    return [
      UserProfile.GESTOR_INSTITUCIONAL_ALLIANCE,
      UserProfile.GESTOR_INSTITUCIONAL_LATAM_ALLIANCE,
      UserProfile.ADMINISTRADOR_ALLIANCE,
    ].some((profile) => profile.includes(this.userDetails.profileName));
  }

  hasRoles(role: RoleUserEnum | RoleUserEnum[]): boolean {
    if (!this.userDetails?.roles) {
      return false;
    }
    return this.userDetails.roles.some((userRole) => role.includes(userRole));
  }

  private requestUserDetails(): Observable<UserDetails> {
    return this._api
      .get<UserDetails>(`/v1/users/details`)
      .pipe(
        tap((data) =>
          this._storageServe.save('userDetails', JSON.stringify(data))
        )
      );
  }
}
