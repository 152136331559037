import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import { ApiService } from './common/api.service';

import { Channel } from '../models/channel';
import { StorageService } from 'src/app/storage/services/storage.service';
import { HttpHeaders } from '@angular/common/http';

export interface CreateChannel {
  id: number;
  apm: string;
  name: string;
  latam: boolean;
  logo?: string | ArrayBuffer;
  channelsAuthenticate: [
    {
      id: number;
      active: boolean;
      channelClientID: string;
      userType: any;
      linksChannel?: [
        {
          id: number;
          link: string;
        }
      ];
    }
  ];
}
@Injectable({
  providedIn: 'root',
})
export class ChannelApiService {
  // channel$: Subject<string> = new BehaviorSubject<string>('');
  channelId$: Subject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(
    private _storageServe: StorageService,
    private _apiService: ApiService
  ) {
    const channel = this.channel();
    if (channel) {
      this.setCurrentChannel(channel);
    }
  }

  getChannelId(): Observable<string> {
    return this.channel$;
  }

  get channel$(): Subject<string> {
    return new BehaviorSubject<string>(this.channelName());
  }

  getChannels() {
    const channelJSON = this._storageServe.get('userChannel');
    const channel = JSON.parse(channelJSON || '{}');

    return channel;
  }

  setCurrentChannel(channel: Channel): Observable<boolean> {
    this.setCurrentChannelName(channel.name);
    this.setCurrentChannelId(channel.clientId || '');
    this._storageServe.save('userChannel', JSON.stringify(channel));
    return of(true);
  }

  private setCurrentChannelName(channelName: string): void {
    this.channel$.next(channelName);
  }
  private setCurrentChannelId(channelId: string): void {
    this.channelId$.next(channelId);
  }

  showChannelTypes(): Observable<Channel[]> {
    return this._apiService.get<Channel[]>(`/v1/channelsType`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }
  showAdminChannelTypes(): Observable<
    Array<{ id: number; description: string }>
  > {
    return this._apiService.get<Array<{ id: number; description: string }>>(
      `/v1/admin/channelsType`
    );
  }

  generateChannelList(isLatam: boolean): Observable<Channel[]> {
    return this._apiService.get<Channel[]>(
      `/v1/admin/channels/latam?latam=${isLatam}`
    );
  }
  getAllChannel(): Observable<Channel[]> {
    return this._apiService.get<Channel[]>(`/v1/admin/channels`);
  }
  getChannelById(channelId: number): Observable<any> {
    return this._apiService.get<CreateChannel>(
      `/v1/admin/channel/${channelId}`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }
  createChannel(channel: CreateChannel): Observable<any> {
    return this._apiService.post<CreateChannel, any>(
      `/v1/admin/channel`,
      channel
    );
  }

  private channel(): Channel | null {
    const channel = this._storageServe.get('userChannel');
    return channel ? JSON.parse(channel) : null;
  }
  private channelClientId(): string {
    let clientId = '';
    const channel = this._storageServe.get('userChannel');
    if (channel) {
      clientId = JSON.parse(channel).clientId;
    }
    return clientId;
  }
  private channelName(): string {
    let name = '';
    const channel = this._storageServe.get('userChannel');
    if (channel) {
      name = JSON.parse(channel).name;
    }
    return name;
  }
  private channelType(): string {
    let type = '';
    const channel = this._storageServe.get('userChannel');
    if (channel) {
      type = JSON.parse(channel).type;
    }
    return type;
  }

  isGrafana(): boolean {
    return this.channelType().toLocaleLowerCase() === 'grafana';
  }
  isAlliance(): boolean {
    return this.channelType().toLocaleLowerCase() === 'alliance';
  }
  isLatamAlliance(): boolean {
    return this.channelType().toLocaleLowerCase() === 'latam_alliance';
  }
}
