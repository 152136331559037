<div class="main-toolbar">

  <img mat-card-image [src]="partner?.logo" alt="logo">

  <div class="persona_info">
    <button mat-button [disabled]="disableChannelClick" [routerLink]="['/'+ partnerName  +'/channels']"
      [state]="{changeChannel: true}" matTooltip="{{'CLICK_TO_CHANGE_CHANNEL' | translate}}" class="user-channel">
      {{ channel$ | async }}
      <mat-icon svgIcon="transfer"></mat-icon>
    </button>

    <button mat-menu-item [matMenuTriggerFor]="extendsMenu" class="user-name">
      {{'HELLO' | translate}}{{socialName}}
      <mat-icon svgIcon="user-circle"></mat-icon>
    </button>
  </div>

</div>



<mat-menu class="menu-con-user-name-auth" #extendsMenu="matMenu">
  <button *ngIf="userCanChangePassword" mat-menu-item (click)="changePassword()">
    <mat-icon svgIcon="lock2"></mat-icon> {{'CHANGE_PASSWORD' | translate}}
  </button>

  <button mat-menu-item [matMenuTriggerFor]="languages">
    <mat-icon svgIcon="globe"></mat-icon> {{'CHANGE_LANGUAGE' | translate}}
  </button>

  <button mat-menu-item (click)="logout()">
    <mat-icon svgIcon="logout"></mat-icon> {{'SIGN_OUT' | translate}}
  </button>
</mat-menu>


<mat-menu #languages="matMenu">
  <button *ngIf="currentLanguage.value != 'pt-BR'" mat-menu-item (click)="changeLanguage('pt-BR')">
    <mat-icon svgIcon="br"></mat-icon>
    Português</button>
  <button *ngIf="currentLanguage.value != 'en-US'" mat-menu-item (click)="changeLanguage('en-US')">
    <mat-icon svgIcon="us"></mat-icon>
    English</button>
  <button *ngIf="currentLanguage.value != 'es'" mat-menu-item (click)="changeLanguage('es')">
    <mat-icon svgIcon="es"></mat-icon>
    Español</button>
</mat-menu>
