import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { AllPartnerItems, AllThemeItems, Partner } from 'src/partner/models';
import { PartnerEnum } from 'src/partner/partner.enum';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
  PartnerAutomation,
  ServiceContractService,
} from './service-contracts.service';
import { AppPartner } from 'src/partner/partner.service';
import {
  ColorPaletteService,
  PaletteHex,
} from '../helpers/color-palette.service';
import { GlobalizationService } from '../globalization/services/globalization.service';
import { StorageService } from '../storage/services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class PartnerGuardService {
  private _partnerActive?: Partner;
  private readonly _replacedVariables: string[] = [
    '--mdc-filled-text-field-error-label-text-color',
    '--mdc-filled-text-field-error-caret-color',
    '--mdc-filled-text-field-error-focus-active-indicator-color',
    '--mdc-filled-text-field-error-active-indicator-color',
    '--mdc-filled-text-field-error-hover-label-text-color',
    '--mdc-filled-text-field-error-hover-active-indicator-color',
    '--mdc-filled-text-field-error-focus-label-text-color',
    '--primary-color',
  ];

  constructor(
    private readonly _serviceContractService: ServiceContractService,
    private readonly colorPaletteService: ColorPaletteService,
    private readonly _appPartner: AppPartner,
    private readonly _globalizationService: GlobalizationService,
    private readonly _storageService: StorageService,
    public router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const partner = route.paramMap.get('partner') ?? '';

    if (AllThemeItems.some((p) => p === partner) || partner === '') {
      this._partnerActive = AllPartnerItems.filter((t) =>
        t.theme.includes(route.paramMap.get('partner'))
      )[0];
    }

    if (this._appPartner.getPartnerValue()) {
      return true;
    }

    return this._serviceContractService.getPartnerInfo(partner).pipe(
      map((data) => {
        if (data) {
          const primaryColor =
            data.primaryColor ?? this._partnerActive?.primaryColor ?? '#ff6600';
          this.applyPartnerConfig(data, partner);
          const palette =
            this.colorPaletteService.generatePalette(primaryColor);
          this.updateTheme(palette);
          this.setUserLanguage(partner, data.language);
          this.updateVariableColor({
            primaryColor,
            secondaryColor: data.secondaryColor,
            backgroundColor: data.backgroundColor,
          });
          return true;
        } else {
          this.router.navigateByUrl('accessmanagement/error-page-unavailable', {
            skipLocationChange: true,
          });
          return false;
        }
      }),
      catchError(() => {
        this.router.navigateByUrl('accessmanagement/error-page-unavailable', {
          skipLocationChange: true,
        });
        return of(false);
      })
    );
  }

  applyPartnerConfig(data: PartnerAutomation, partnerName: string) {
    const newPartner = {
      institutionCod: data.institutionCod,
      channelType: data.channelType,
      language: data.language,
      channelClientId: data.channelClientId,
      logo: data.logo ?? this._partnerActive?.logo ?? PartnerEnum.FiservLogo,
      serviceContract: data.serviceContractCod.toString(),
      partnerName: data.institutionName ?? partnerName,
      routerPartnerName: partnerName,
      theme: this._partnerActive?.theme ?? 'default',
    };
    this._appPartner.setPartner(newPartner);
  }

  updateTheme(palette: PaletteHex) {
    const root = document.documentElement;
    const arrayOfPalette: any = Object.keys(palette);
    for (let i = 0, len = arrayOfPalette.length; i < len; i++) {
      root.style.setProperty(
        `--palette-${arrayOfPalette[i]}`,
        palette[arrayOfPalette[i]]
      );
    }
  }

  updateVariableColor({ primaryColor, secondaryColor, backgroundColor }: any) {
    document.documentElement.style.setProperty(
      '--background-color',
      backgroundColor || null
    );
    document.documentElement.style.setProperty(
      '--secondary-color',
      secondaryColor || null
    );
    for (const element of this._replacedVariables) {
      document.documentElement.style.setProperty(element, primaryColor || null);
    }
  }

  setUserLanguage(partner: string, language: string = 'en-US') {
    const userHasSetLanguage = this._storageService.getUsingPrefix(
      'current_language',
      `_${partner}_`
    );
    if (userHasSetLanguage) {
      this._globalizationService.setCurrentLanguageWithPrefix(
        userHasSetLanguage,
        `_${partner}_`
      );
    } else if (language) {
      this._globalizationService.setTemporaryLanguage(language);
    } else {
      this._globalizationService.setTemporaryLanguage('pt-BR');
    }
  }
}
